<template>
	<v-layout column fill-height>
		<v-flex shrink>
			<v-layout v-if="isAccountant" justify-end>
				<w-btn icon="add" @click="launchAccountingPeriodCreation()">{{ $t('accounting_periods.actions.create') }}</w-btn>
			</v-layout>
		</v-flex>
		<v-flex scroll-y>
			<v-layout column wrap>
				<v-flex pt-1 scroll-x>
					<v-data-table class="elevation-1" :headers="headers" :items="accountingPeriods" item-key="id" :loading="loading" :pagination.sync="pagination">
						<template v-slot:items="props">
							<tr
								:active="props.selected"
								:class="isAccountant && !props.item.deleted_at && props.item.is_complete ? 'pointer' : ''"
								@click="isAccountant && !props.item.deleted_at && props.item.is_complete ? launchAccountingPeriodEdition(props.item) : null"
							>
								<td>
									<span :class="{ 'crossed--text red--text': props.item.deleted_at }">{{ props.item.name }}</span>
									&nbsp;
									<span v-if="props.item.deleted_at">({{ $t('accounting_periods.deletion_in_progress') }})</span>
									<span v-if="!props.item.is_complete">({{ $t('accounting_periods.creation_in_progress') }})</span>
								</td>
								<td :class="{ 'crossed--text red--text': props.item.deleted_at, 'text-xs-center': true }">
									{{ $translateDateTime(props.item.start, 'tiny', true) }}
								</td>
								<td :class="{ 'crossed--text red--text': props.item.deleted_at, 'text-xs-center': true }">
									{{ $translateDateTime(props.item.end, 'tiny', true) }}
								</td>
								<td v-if="isAccountant" class="text-xs-center">
									<w-btn-delete
										:disabled="!!props.item.deleted_at"
										:loading="props.item.deletion_loading || !!props.item.deleted_at"
										mini
										@click.stop="launchAccountingPeriodDeletion(props.item)"
									/>
								</td>
							</tr>
						</template>
					</v-data-table>
				</v-flex>
				<v-flex mb-4 mx-auto text-xs-right xs10>
					<w-dialog
						v-model="dialog"
						:max-width="$vuetify.breakpoint.xl ? '40vw' : '90vw'"
						:title="accountingPeriod.id ? $t('accounting_periods.actions.modify') : $t('accounting_periods.actions.create')"
					>
						<AccountingPeriodForm ref="form" v-model="accountingPeriod" :accounting-periods="accountingPeriods" :is-valid.sync="isValid" />
						<template v-slot:actions>
							<v-spacer />
							<w-btn-save :disabled="!isValid" flat :loading="formLoading" @click="accountingPeriod.id ? updateAccountingPeriod() : createAccountingPeriod()" />
						</template>
					</w-dialog>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/VendorSettings/VendorsSettingsModuleGuard'
import Validator from '@/mixins/Validator'

export default {
	name: 'CustomerDetailsPeriods',
	components: {
		AccountingPeriodForm: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/AccountingPeriods/AccountingPeriodForm')
		})
	},
	mixins: [CustomersManagerModuleGuard, Validator],
	data: function () {
		return {
			accountingPeriod: {
				end: null,
				name: null,
				start: null
			},
			accountingPeriodCopied: {},
			accountingPeriods: [],
			dialog: false,
			formLoading: false,
			isValid: false,
			loading: false,
			pagination: {
				descending: true,
				sortBy: 'end',
				rowsPerPage: 10,
				page: 1
			}
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant,
			vendor: state => state.company.selected
		}),
		headers: function () {
			const result = [
				{ align: 'center', text: this.$t('accounting_periods.name'), value: 'name' },
				{ align: 'center', text: this.$t('accounting_periods.start'), value: 'start' },
				{ align: 'center', text: this.$t('accounting_periods.end'), value: 'end' }
			]

			if (this.isAccountant) {
				result.push({ align: 'center', sortable: false })
			}

			return result
		}
	},
	watch: {
		vendor: {
			handler: function () {
				this.loadAccountingPeriods()
			}
		}
	},
	beforeDestroy: function () {
		this.emptyAccountingPeriodsList()
	},
	mounted: function () {
		this.loadAccountingPeriods()
	},
	methods: {
		checkIfAccountingPeriodExists: function (accountingPeriodId) {
			return setTimeout(
				function () {
					this.service
						.findAccountingPeriod(
							this.vendorId,
							accountingPeriodId,
							{
								only_trashed: true
							},
							{ show_error: false }
						)
						.then(() => {
							const accountingPeriod = this.accountingPeriods.find(({ id }) => id == accountingPeriodId)
							if (!accountingPeriod) {
								return
							}
							accountingPeriod.delete_at_timeout = this.checkIfAccountingPeriodExists(accountingPeriodId)
							return accountingPeriod.delete_at_timeout
						})
						.catch(() => {
							const index = this.accountingPeriods.findIndex(({ id }) => id == accountingPeriodId)
							if (index == -1) {
								return
							}
							this.accountingPeriods.splice(index, 1)
						})
				}.bind(this),
				3000
			)
		},
		checkIfAccountingPeriodIsComplete: function (accountingPeriodId) {
			return setTimeout(
				function () {
					this.service
						.findAccountingPeriod(this.vendorId, accountingPeriodId, {}, { show_error: false })
						.then(accountingPeriodUpdated => {
							const accountingPeriod = this.accountingPeriods.find(({ id }) => id == accountingPeriodId)
							if (accountingPeriodUpdated.is_complete) {
								accountingPeriod.is_complete = true
								return
							}
							accountingPeriod.create_at_timeout = this.checkIfAccountingPeriodIsComplete(accountingPeriodId)
							return accountingPeriod.create_at_timeout
						})
				}.bind(this),
				3000
			)
		},
		createAccountingPeriod: function () {
			this.formLoading = true
			return this.service
				.createAccountingPeriod(this.vendor.id, this.accountingPeriod)
				.then(accountingPeriod => {
					Vue.set(accountingPeriod, 'creation_loading', true)
					this.accountingPeriods.push(accountingPeriod)
					accountingPeriod.is_complete = false
					accountingPeriod.create_at_timeout = this.checkIfAccountingPeriodIsComplete(accountingPeriod.id)
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('accounting_periods.actions.created'))
					this.dialog = false
				})
				.finally(() => {
					this.formLoading = false
				})
		},
		deleteAccountingPeriod: function (accountingPeriod) {
			Vue.set(accountingPeriod, 'deletion_loading', true)
			this.service
				.deleteAccountingPeriod(this.vendorId, accountingPeriod.id)
				.then(() => {
					const index = this.accountingPeriods.findIndex(({ id }) => id == accountingPeriod.id)
					if (index != -1) {
						this.accountingPeriods[index].deleted_at = true
						this.accountingPeriods[index].delete_at_timeout = this.checkIfAccountingPeriodExists(accountingPeriod.id)
					}
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('accounting_periods.actions.deleted'))
				})
				.catch(() => {
					accountingPeriod.deletion_loading = false
				})
		},
		emptyAccountingPeriodsList: function () {
			this.accountingPeriods.forEach(accountingPeriod => {
				if (accountingPeriod.deleted_at_timeout) {
					clearTimeout(accountingPeriod.deleted_at_timeout)
				}
			})
			this.accountingPeriods.splice(0, this.accountingPeriods.length)
		},
		launchAccountingPeriodCreation: function () {
			this.$refs.form.resetValidation()
			this.accountingPeriod = {
				end: null,
				name: null,
				start: null
			}
			this.dialog = true
		},
		launchAccountingPeriodDeletion: function (accountingPeriod) {
			return this.$dialog
				.warning({
					text: this.$t('accounting_periods.actions.delete_confirmation', { name: accountingPeriod.name }),
					title: this.$t('accounting_periods.actions.delete'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						return this.deleteAccountingPeriod(accountingPeriod)
					}
				})
		},
		launchAccountingPeriodEdition: function (accountingPeriod) {
			this.$refs.form.resetValidation()
			this.accountingPeriod = { ...accountingPeriod }
			this.dialog = true
		},
		loadAccountingPeriods: function () {
			this.loading = true

			return this.service
				.listAccountingPeriods(this.vendor.id, { with_trashed: true })
				.then(accountingPeriods => {
					this.emptyAccountingPeriodsList()
					accountingPeriods.forEach(accountingPeriod => {
						this.accountingPeriods.push(accountingPeriod)
						if (accountingPeriod.deleted_at) {
							accountingPeriod.delete_at_timeout = this.checkIfAccountingPeriodExists(accountingPeriod.id)
						}
						if (!accountingPeriod.is_complete) {
							accountingPeriod.create_at_timeout = this.checkIfAccountingPeriodIsComplete(accountingPeriod.id)
						}
					})
				})
				.finally(() => {
					this.loading = false
				})
		},
		updateAccountingPeriod: function () {
			this.formLoading = true

			return this.service
				.updateAccountingPeriod(this.vendor.id, this.accountingPeriod)
				.then(accountingPeriod => {
					const ap = this.accountingPeriods.find(ap => ap.id == accountingPeriod.id)
					if (ap) {
						Object.assign(ap, accountingPeriod)
					} else {
						this.accountingPeriods.push(accountingPeriod)
					}
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('accounting_periods.actions.modified'))
					this.dialog = false
				})
				.finally(() => {
					this.formLoading = false
				})
		}
	}
}
</script>
